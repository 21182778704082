<template>
  <v-container fluid>
    <v-row>
        <v-col sm-12 md-12 lg-12>
            Thank you for submitting your response.
        </v-col>
    </v-row>      
    <v-row>
        <v-col class="d-flex justify-end" sm-12 md-12 lg-12>
            <v-btn color="primary" @click="goToStart()">Back</v-btn>
        </v-col>
    </v-row>        
  </v-container>
</template>

<script>

export default {
  data() {
    return {
    };
  },
  methods: {
    goToStart() {
        this.$router.push({ name: 'iReviewLanding'})
    }
  }
};
</script>